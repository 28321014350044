import React from "react"
import Page from 'components/Page'
import Layout from "components/Layout"
import { graphql } from 'gatsby'
import useStoryblok from 'utils/storyblok'
import { PageContext } from "utils/context";

export default function Index({ data, location, pageContext }) {
    const story = useStoryblok(data.story, location)

    return (
      <PageContext.Provider
        value={{
          ...story,
          ...pageContext,
          location: location,
        }}
      >
        <Layout location={location}>
          <Page blok={story} />
        </Layout>
      </PageContext.Provider>
    )
}

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "home" }) {
      name
      content
      full_slug
      uuid
    }
  }
`